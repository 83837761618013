import { type AmountPrice, type GetListingQuery } from '@kijiji/generated/graphql-types'

export const isAmountPrice = (listingPrice?: unknown): listingPrice is AmountPrice => {
  const price = listingPrice as NonNullable<GetListingQuery['listing']>['price']

  if (
    price &&
    (price.__typename === 'AutosDealerAmountPrice' ||
      price?.__typename === 'StandardAmountPrice' ||
      'amount' in price)
  ) {
    return true
  }

  return false
}
